export const useGlobalStore = defineStore('global', () => {
  // state
  const isLoading = ref(true)
  const isProcessing = ref(false)
  const pageTitle = ref('')
  const spoofBar = ref(false)
  const searchBar = ref(false)

  return {
    isLoading,
    isProcessing,
    pageTitle,
    spoofBar,
    searchBar
  }
})
